import React, { useEffect, useContext } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import HomeMobile from "./components/pages/home/mobile/HomeMobile";
import HomeDesktop from "./components/pages/home/desktop/HomeDesktop";
import GalleryMobile from "./components/pages/gallery/mobile/GalleryMobile";
import GalleryDesktop from "./components/pages/gallery/desktop/GalleryDesktop";
import ContactMobile from "./components/pages/contact/mobile/ContactMobile";
import ContactDesktop from "./components/pages/contact/desktop/ContactDesktop";
import CurrentProjectMobile from "./components/pages/currentProject/mobile/CurrentProjectMobile";
import CurrentProjectDesktop from "./components/pages/currentProject/desktop/CurrentProjectDesktop";
import FooterDesktop from './components/shared/footer/desktop/FooterDesktop';
import FooterMobile from './components/shared/footer/mobile/FooterMobile';
import NavBarDesktop from './components/shared/navBar/desktop/NavBarDesktop';
import NavBarMobile from './components/shared/navBar/mobile/NavBarMobile';
import { MyContext } from './context/MyContext';
import AboutUsMobile from './components/pages/aboutUs/mobile/AboutUsMobile';
import AboutUsDesktop from './components/pages/aboutUs/desktop/AboutUsDesktop';
import GoldGlobalMarketMobile from './components/pages/more/gold-global-market/mobile/GoldGlobalMarketMobile';
import GoldGlobalMarketDesktop from './components/pages/more/gold-global-market/desktop/GoldGlobalMarketDesktop';
import GoldMiningExplorationMobile from './components/pages/more/gold-mining-exploration/mobile/GoldMiningExplorationMobile';
import GoldMiningExplorationDesktop from './components/pages/more/gold-mining-exploration/desktop/GoldMiningExplorationDesktop';


function App() {

  const { isMobile, setIsMobile } = useContext(MyContext);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);



  useEffect(() => {
    // Function to check window width and update state
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check to set the correct view on load
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>

      {
        isMobile ? <NavBarMobile /> : <NavBarDesktop />
      }
      <Routes>
        <Route path="/" element={isMobile ? <HomeMobile /> : <HomeDesktop />} />
        <Route path="/about-us" element={isMobile ? <AboutUsMobile /> : <AboutUsDesktop />} />
        <Route path="/contact" element={isMobile ? <ContactMobile /> : <ContactDesktop />} />
        <Route path="/gallery" element={isMobile ? <GalleryMobile /> : <GalleryDesktop />} />
        <Route path="/current-project" element={isMobile ? <CurrentProjectMobile /> : <CurrentProjectDesktop />} />
        <Route path="/global-gold-market" element={isMobile ? <GoldGlobalMarketMobile /> : <GoldGlobalMarketDesktop />} />
        <Route path="/gold-mining-exploration" element={isMobile ? <GoldMiningExplorationMobile /> : <GoldMiningExplorationDesktop />} />
      </Routes>
      {
        isMobile ? <FooterMobile /> : <FooterDesktop />
      }

    </div>
  );
}

export default App;

import React from 'react'
import "./GalleryDesktop.css"
import GPhoto1 from "../../../../assets/images/g-photo-1.jpg"
import GPhoto2 from "../../../../assets/images/g-photo-2.jpg"
import GPhoto3 from "../../../../assets/images/g-photo-3.jpg"
import GPhoto4 from "../../../../assets/images/g-photo-4.jpg"
import GPhoto5 from "../../../../assets/images/g-photo-5.jpg"
import GPhoto6 from "../../../../assets/images/g-photo-6.jpg"
import GPhoto7 from "../../../../assets/images/g-photo-7.jpg"
import GPhoto8 from "../../../../assets/images/g-photo-8.jpg"
import SomImage from "../../../../assets/images/som-img.svg"
import GImage1 from "../../../../assets/images/GImage1.jpg"
import GImage2 from "../../../../assets/images/GImage2.jpg"
import GImage3 from "../../../../assets/images/GImage3.jpg"
import GImage4 from "../../../../assets/images/GImage4.jpg"
import GImage5 from "../../../../assets/images/GImage5.jpg"
import GImage6 from "../../../../assets/images/GImage6.jpg"
import GImage7 from "../../../../assets/images/GImage7.jpg"
import GImage8 from "../../../../assets/images/GImage8.jpg"
import GImage9 from "../../../../assets/images/GImage9.jpg"
import GImage10 from "../../../../assets/images/GImage10.jpg"
import GImage11 from "../../../../assets/images/GImage11.jpg"
import image1 from "../../../../assets/images/image1.jpg"
import image2 from "../../../../assets/images/image2.jpg"
import image3 from "../../../../assets/images/image3.jpg"
import image4 from "../../../../assets/images/image4.jpg"
import image5 from "../../../../assets/images/image5.jpg"
import image6 from "../../../../assets/images/image6.jpg"

function GalleryDesktop() {
  const MultiImages = [image1, image2, image3, image4, image5, image6]
  return (
    <div className='gallery-main-container'>
      <div className='gallery-first-container'>
        <div className='km-container'>
          <div className='km-text-head'>KEY MOMENTS</div>
          <div>Explore pivotal moments in gold mining history and market evolution. From groundbreaking discoveries to major industry advancements, these key events have shaped the global gold market and its future.</div>
        </div>
        <div className='marquee-container'>

          <div class="marquee">
            <div className='mq-sc'>
              <><img src={GPhoto1} alt='g' /></>
              <><img src={GPhoto2} alt='g' /></>
              <><img src={GPhoto3} alt='g' /></>
              <><img src={GPhoto4} alt='g' /></>
              <><img src={GPhoto5} alt='g' /></>
              <><img src={GPhoto6} alt='g' /></>
              <><img src={GPhoto7} alt='g' /></>
              <><img src={GPhoto8} alt='g' /></>
            </div>
          </div>
        </div>
      </div>
      <div className='multi-img-contt'>
        <div className='multi_image_container'>
          {MultiImages?.map((item) => (
          <img src={item} alt='mi'/>
          ))}
        </div>
      </div>

      <div className='second-marquee-contt'>
        <div class="marquee">
          <div className='mq-scc'>
            <><img src={GImage1} alt='g'/></>
            <><img src={GImage2} alt='g'/></>
            <><img src={GImage3} alt='g'/></>
            <><img src={GImage4} alt='g'/></>
            <><img src={GImage6} alt='g'/></>
            <><img src={GImage7} alt='g'/></>
            <><img src={GImage8} alt='g'/></>
            <><img src={GImage9} alt='g'/></>
            <><img src={GImage10} alt='g'/></>
            <><img src={GImage5} alt='g'/></>
            <><img src={GImage11} alt='g'/></>
          </div> </div>
      </div>

      <div className='som-container'>
        <div className='som-text'>SOME OTHER MOMENTS</div>
        <div className='som-img-contt'><img src={SomImage} className='multi-image' alt='mi'/></div>
      </div>
    </div>
  )
}

export default GalleryDesktop

import React, { useEffect } from "react";
import heroImage from "../../../../../assets/images/goldball.jpg";
import Geo from "../../../../../assets/images/geopolitical.jpg";
import Infl from "../../../../../assets/images/infl.jpg";
import StarImg from "../../.../../../../../assets/images_backup/starimg.svg";
import Countryimg from "../../.../../../../../assets/images/country_charts.jpg";
import CurrencyImg from "../../.../../../../../assets/images/currency.jpg";
import GoldCompanies from "../../.../../../../../assets/images/gold_companies.jpg";
import PieChart from "../../.../../../../../assets/images/investmentChart.jpg";
import GoldGlobalPieChart from "../../.../../../../../assets/images/gold_global_market.jpg";
import Yellow from "../../../../../../src/assets/images_backup/yellowimg.svg";

import "./GoldGlobalMarketMobile.css";

function GoldGlobalMarketMobile() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [["TVC:GOLD|120M"]],
      chartOnly: false,
      width: "100%",
      height: "100%",
      locale: "en",
      colorTheme: "light",
      autosize: false,
      showVolume: true,
      showMA: false,
      hideDateRanges: false,
      hideMarketStatus: false,
      hideSymbolLogo: false,
      scalePosition: "right",
      scaleMode: "Normal",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      fontSize: "10",
      noTimeScale: false,
      valuesTracking: "2",
      changeMode: "price-and-percent",
      chartType: "area",
      lineWidth: 2,
      lineType: 0,
      dateRanges: ["120m|1M"],
      downColor: "#f7525f",
      upColor: "#22ab94",
    });
    document.getElementById("tradingview-widget").appendChild(script);
  }, []);

  return (
    <>
      <div className="ggm_mobile_container">
        {/* hero container starts here  */}
        <div className="ggm_hero_section">
          <div className="ggm_hero_text">
            <h1>Global Gold Market at a Glance</h1>
            <p>Explore Gold Prices, Top Producers, and Global Reserves</p>
          </div>
          <div className="ggm_image">
            <img src={heroImage} width={200} height={200} alt="gold"/>
          </div>
        </div>

        {/* hero Intro container starts here  */}

        <div className="ggm_intro_container">
          <div className="ggm_intro_text">
            <h2>Understanding the Global Gold Market</h2>
            <p>
              Gold, with the chemical symbol Au (from the Latin word Aurum) and
              an atomic number of 79, is a slightly orange-yellow, dense, soft,
              malleable, and ductile element. As a transition metal, gold is one
              of the least reactive chemical elements, which is why it is often
              found in its native state on Earth, as nuggets or grains in rocks,
              veins, and alluvial deposits. It occurs in solid solution with
              native silver and is naturally alloyed with other metals like
              copper and palladium, as well as mineral inclusions such as
              pyrite.
            </p>
          </div>
          <div className="ggm_main_text">
            <h3>
              Introduce the major participants in the gold market and explain
              their roles
            </h3>
          </div>
          <div className="ggm_main_sub_text_container">
            <div className="ggm_info">
              <div className="ggm_Info_icons">
                <img src={Yellow} width={60} height={60} alt="gold"/>
              </div>
              <div className="ggm_icons_text">
                <h4>Mining Company</h4>
                <p>
                  Mining companies extract gold and play a central role in
                  determining supply.
                </p>
              </div>
            </div>

            <div className="ggm_info">
              <div className="ggm_Info_icons">
                <img src={Yellow} width={60} height={60} alt="gold"/>
              </div>
              <div className="ggm_icons_text">
                <h4>Mining Company</h4>
                <p>
                  Mining companies extract gold and play a central role in
                  determining supply.
                </p>
              </div>
            </div>

            <div className="ggm_info">
              <div className="ggm_Info_icons">
                <img src={Yellow} width={60} height={60} alt="gold"/>
              </div>
              <div className="ggm_icons_text">
                <h4>Mining Company</h4>
                <p>
                  Mining companies extract gold and play a central role in
                  determining supply.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* geo political section starts here  */}

        <div className="geo_political_container">
          <h2>Geopolitical Factors</h2>
          <p>
            The 2008 recession and the Lehmann Brothers chaos led to global
            markets bleeding red. The volatility of the global markets for the
            next few years resulted in the gold prices jumping from $700 per
            ounce in 2008 to $1,900 per ounce in 2011.
          </p>
          <img src={Geo} width={"100%"} height={"100%"} alt="gold"/>
        </div>

        {/* geo inflation section starts here  */}

        <div className="geo_inflation_container">
          <h2>Inflation vs. Gold Price</h2>
          <p>
            Gold is known to be inflation hedge and this reputation is
            well-earned. Since inflation means the decrease in the value of fiat
            (paper, unbacked by metals) money, people turn to assets that proved
            to be money throughout history - gold & silver. Platinum used to be
            money as well, but it's not as recognizable as silver and gold, so
            the reputation of the ultimate inflation- hedge goes to the former
            metals, gold in particular (silver being somewhat industrial metal
            nowadays).
          </p>
          <img src={Infl} width={"100%"} height={"100%"} alt="gold"/>
        </div>

        {/* economic conditions section starts here  */}

        <div className="economic_conditions_container">
          <h2>ECONOMIC CONDITIONS</h2>
          <div className="economic_body_text">
            <div className="economic_body_text1">
              <h4>Interest Rates</h4>
            </div>
            <h4 className="vs">VS</h4>
            <div className="economic_body_text2">
              <h4>Gold Price</h4>
            </div>
          </div>
          <div className="economic_main_text">
            <p>
              The 2008 recession and the Lehmann Brothers chaos led to global
              markets bleeding red. The volatility of the global markets for the
              next few years resulted in the gold prices jumping from $700 per
              ounce in 2008 to $1,900 per ounce in 2011.
            </p>
          </div>

          <div className="economic_graph_text1">
            <h3>Geopolitical Factors</h3>
            <img src={Infl} width={"100%"} height={"100%"} alt="gold"/>
          </div>

          <div className="economic_graph_text2">
            <h3>Currency Strength</h3>
            <img src={CurrencyImg} width={"100%"} height={"100%"} alt="gold"/>
          </div>
        </div>

        {/* investment demands section starts here  */}

        <div className="investment_demand_container">
          <div>
            <img src={PieChart} width={"100%"} height={"100%"} alt="gold"/>
            <div className="investment_demand_text">
              <h2>Investment Demand</h2>
              <h5>Physical Gold vs. Paper Gold Demand</h5>
            </div>
          </div>
        </div>

        {/* gold global market section starts here  */}

        <div className="gold_global_market_container">
          <h3>GOLD GLOBAL MARKET: UTILITY OF GOLD</h3>
          <h5>This pie chart highlights the main uses of gold in 2022</h5>
          <ul>
            <li>Jewellery manufacturing</li>
            <li>Bullion (gold bars and ingots)</li>
            <li>Gold coins and medals</li>
            <li>Electronics industry</li>
            <li>Other uses</li>
          </ul>
          <p>
            This data is from the USGS Mineral Commodity Summaries, January
            2023.
          </p>
          <img src={GoldGlobalPieChart} width={"100%"} height={"100%"} alt="gold"/>
        </div>

        {/* cfds dynamic pie charts  */}

        <div className="cfd-container d-flex justify-content-center align-items-center">
          <div
            id="tradingview-widget"
            style={{ width: "100%", height: "100%" }}
          />
          ;
        </div>

        {/* gold producing contries section starts here  */}

        <div className="gold_producing_company_container">
          <h2>Top Gold Producing Countries Worldwide</h2>
          <div className="gold_producing_company_bg">
            <img src={GoldCompanies} width={"100%"} height={"100%"} alt="gold"/>
          </div>
        </div>

        {/* future outlook sections css starts here  */}

        <div className="future_outlook_container">
          <h2>Future Outlook: Emerging Gold Producers</h2>
          <p>Exploring the Regions Poised to Shape the Global Gold Market</p>

          <div className="future_outlook_container_body">
            <div className="future_outlook_container_content">
              <div className="future_outlook_cards">
                <img src={StarImg} alt="yellow" />
                <div className="">
                  <p className="demand-text ">Investment Demand</p>
                  <p className="demand-subtext">
                    Gold will remain a safe-haven asset during economic
                    uncertainty, driven by inflation and market volatility.
                  </p>
                </div>
              </div>

              <div className="future_outlook_cards">
                <img src={StarImg} alt="yellow" />
                <div className="">
                  <p className="demand-text ">Jewellery Demand</p>
                  <p className="demand-subtext">
                    Increased disposable incomes in emerging markets,
                    particularly in India and China, will boost gold jewelry
                    consumption.
                  </p>
                </div>
              </div>

              <div className="future_outlook_cards">
                <img src={StarImg} alt="yellow" />
                <div className="">
                  <p className="demand-text ">Industrial Applications</p>
                  <p className="demand-subtext">
                    Continued purchases by central banks to hedge against
                    instability will affect overall demand.
                  </p>
                </div>
              </div>

              <div className="future_outlook_cards">
                <img src={StarImg} alt="yellow" />
                <div className="">
                  <p className="demand-text ">Central Bank Reserves</p>
                  <p className="demand-subtext">
                    Gold will remain a safe-haven asset during economic
                    uncertainty, driven by inflation and market volatility.
                  </p>
                </div>
              </div>

              <div className="future_outlook_cards">
                <img src={StarImg} alt="yellow" />
                <div className="">
                  <p className="demand-text ">Sustainable Practices</p>
                  <p className="demand-subtext">
                    There will be a rising preference for ethically sourced gold
                    due to consumer awareness.
                  </p>
                </div>
              </div>

              <div className="future_outlook_cards">
                <img src={StarImg} alt="yellow" />
                <div className="">
                  <p className="demand-text ">Emerging Markets</p>
                  <p className="demand-subtext">
                    Expanding middle classes in Asia and Africa will drive
                    higher gold consumption.
                  </p>
                </div>
              </div>

              <div className="future_outlook_cards">
                <img src={StarImg} alt="yellow" />
                <div className="">
                  <p className="demand-text ">Digital Gold</p>
                  <p className="demand-subtext">
                    Innovations in digital currencies and blockchain may attract
                    new investors.
                  </p>
                </div>
              </div>

              <div className="future_outlook_cards">
                <img src={StarImg} alt="yellow" />
                <div className="">
                  <p className="demand-text ">Economic Conditions</p>
                  <p className="demand-subtext">
                    Factors like interest rates and inflation will shape gold's
                    investment appeal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* gold reserves worldwide starts here  */}

        <div className="gold_reserve_worldwide_container">
          <h2 className="gold_reserve_heading">Gold Reserves Worldwide</h2>
          <p className="gold_reserve_text">
            <span>10 Countries</span> with the largest gold reserves in the
            world
          </p>
          <div>
            <img src={Countryimg} width={"100%"} height={"100%"} alt="yellow" />
          </div>
        </div>
      </div>
    </>
  );
}

export default GoldGlobalMarketMobile;

import React from 'react'
import "./GoldMiningExplorationDesktop.css";
import Mimageone from "../../../../../assets/images/gme-image-one.jpg";
import Mimagetwo from "../../../../../assets/images/gme-image-two.jpg";
import Mimagethree from "../../../../../assets/images/gme-image-three.jpg";
import Mimagefour from "../../../../../assets/images/gme-image-four.jpg";
import Mimagefive from "../../../../../assets/images/gme-image-five.jpg";
import Mimagesix from "../../../../../assets/images/gme-image-six.jpg";
import Mimageseven from "../../../../../assets/images/gme-image-seven.jpg";
import Mimageeight from "../../../../../assets/images/gme-image-eight.jpg";
import Mimagenine from "../../../../../assets/images/gme-image-nine.jpg";
import Star from "../../../../../assets/images/star-golden.jpg"


function GoldMiningExplorationDesktop() {

  const steps = [
    {
      title: "Area Selection",
      details: [
        "Research and Data Collection: Utilize geological maps and historical data to find regions with potential gold deposits.",
        "Satellite Imagery and Remote Sensing: Use technology to spot promising features remotely.",
      ],
    },
    {
      title: "Geological Mapping",
      details: [
        "Field Surveys: Conduct surveys to map geological features like rock formations and fault lines.",
        "Structural Analysis: Study geological structures to locate potential gold deposits.",
      ],
    },
    {
      title: "Geochemical Analysis",
      details: [
        "Sample Collection: Collect soil, rock, and sediment samples systematically.",
        "Laboratory Testing: Analyze samples for trace elements indicating gold presence.",
      ],
    },
    {
      title: "Geophysical Surveys",
      details: [
        "Magnetic Surveys: Detect magnetic variations in rocks.",
        "Seismic and Electromagnetic Surveys: Identify subsurface structures and ore bodies.",
      ],
    },
    {
      title: "Drilling",
      details: [
        "Core and Reverse Circulation Drilling: Extract samples to assess mineral content.",
        "Assaying: Test samples for gold concentration.",
      ],
    },
    {
      title: "Resource Estimation",
      details: [
        "Data Analysis: Estimate deposit size and grade using drilling data.",
        "3D Modelling: Visualize the deposit with 3D software.",
      ],
    },
    {
      title: "Feasibility Studies",
      details: [
        "Economic Assessments: Analyze economic viability through PEA, PFS, and FS.",
      ],
    },
    {
      title: "Compliances",
      details: [
        "Environmental Impact Assessments: Mitigate environmental impacts.",
        "Regulatory Approvals: Secure necessary permits and conduct community consultations.",
      ],
    },
    {
      title: "Development & Mining",
      details: [
        "Mine Planning and Construction: Plan and build infrastructure for mining operations.",
        "Production: Begin extraction and processing of gold.",
      ],
    },
    {
      title: "Post-Exploration",
      details: [
        "Environmental Monitoring and Site Rehabilitation: Monitor impacts and restore land post-mining.",
      ],
    },
  ];

  return (
    <div className='gme-continer'>
      <div className='gme-first-container'>
        <div className='gfc-head-container'>
          <div className='gfc-main-heading'>01 To 10 YEARS</div>
          <div className='gfc-sub-heading'>GOLD MINE</div>
          <div className='gfc-sub-heading'>EXPLORATION</div>
          <div className='gmefc-two'>
            <div className='gt-one'>
              <div className='gto-bg'></div>
              <div className='gto-text'>Exploring for gold mines is a challenging and complex process, demanding time, financial investment, and expertise across multiple fields like geography, geology, chemistry, and engineering. The chances of a discovery leading to a developed mine are very slim—less than 0.1% of prospected sites become productive, and only 10% of global gold deposits hold enough gold to justify further exploration. Once the local geology and a potentially viable deposit are understood, the gold ore body can be modeled, and its feasibility assessed.</div>
            </div>
            <div className='gt-two'>
              <div gtt-text>Prospecting</div>
              <div gtt-text>Viability</div>
              <div gtt-text>Viability</div>
            </div>
          </div>
        </div>
      </div>

      <div className='gme-second-container'>
        <div className='gsc-head'>0 TO 05 YEARS</div>
        <div className='gsc-two'>GOLD MINE DEVELOPMENT</div>
        <div className='gsc-three'>Building the Future: From Mine to Community</div>
        <div className='marquee-containerr'>
          <div class="marqueeee">
            <div className='mq-sccc'>
              <><img src={Mimageone} alt='mo'/></>
              <><img src={Mimagetwo} alt='mo'/></>
              <><img src={Mimagethree} alt='mo'/></>
              <><img src={Mimagefour} alt='mo'/></>
              <><img src={Mimagefive} alt='mo'/></>
              <><img src={Mimagesix} alt='mo'/></>
              <><img src={Mimageseven} alt='mo'/></>
              <><img src={Mimageeight} alt='mo'/></>
              <><img src={Mimagenine} alt='mo'/></>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className='main-contt'>
          {steps.map((step, index) => (
            <div key={index} className='table-container'>
              <div className='tc-title'>
                <img src={Star} alt='star'/> {step.title}
              </div>
              <ul>
                {step.details.map((detail, i) => (
                  <li key={i} style={{ marginBottom: "8px" }} className='table-list-item'>
                    {detail}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className='gmo-main-contt'>
        <div className='gmc-contt-one'>
          <div className='gsc-head'>10 TO 35 YEARS</div>
          <div className='gmo-text'>GOLD MINE OPERATION</div>
          <div className='gmo-sub-text'>The operational phase of a gold mine involves extracting and processing ore into doré, a gold alloy of 60-90% purity. Economic viability depends on factors like gold prices and production costs, with low-grade ores becoming profitable when prices rise. Mine plans are regularly updated based on market shifts, technical data, and technological advancements.<div> Increasingly, modern mines use electrification, digitalisation, and automation to enhance efficiency and sustainability, making the gold mining process smarter, cleaner, and more productive."</div> <div>This version is concise yet still conveys the key points.</div></div>
        </div>
        <div className='gmc-contt-two'></div>

      </div>

      <div className='gmo-card-container'>
        <div className='gmo-first-card'>
          <div className='card-top-one'></div>
          <div className='card-bottom'>
            <div className='cb-head'>Closure & Decommissioning</div>
            <div className='cb-text'>Once a mine stops operating, either due to depleted ore or unprofitable deposits, the focus shifts to <span className='cb-span'>Decommissioning, Dismantling, and land rehabilitation.</span> Mine closure is complex, and companies must continue monitoring the site long after closure.</div>
          </div>
        </div>
        <div className='gmo-first-card'>
          <div className='card-top-two'></div>
          <div className='card-bottom'>
            <div className='cb-head'>Closure & Decommissioning</div>
            <div className='cb-text'>Mining companies remain responsible for a site long after closure, usually for 5 to 10 years or more. During this period, the land is<span className='cb-span'> Rehabilitated, cleaned, and re-vegetated</span> to ensure long-term</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoldMiningExplorationDesktop

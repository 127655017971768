import React from 'react'
import "./GoldMiningExplorationMobile.css"
import Mimageone from "../../../../../assets/images/mob-more-marquee-one.jpg"
import Mimagetwo from "../../../../../assets/images/mob-more-marquee-two.jpg"
import Mimagethree from "../../../../../assets/images/mob-more-marquee-three.jpg"
import Mimagefour from "../../../../../assets/images/mob-more-marquee-four.jpg"
import Mimagefive from "../../../../../assets/images/mob-more-marquee-five.jpg"
import Mimagesix from "../../../../../assets/images/mob-more-marquee-six.jpg"
import Mimageseven from "../../../../../assets/images/mob-more-marquee-seven.jpg"
import Mimageeight from "../../../../../assets/images/mob-more-marquee-eight.jpg"
import Mimagenine from "../../../../../assets/images/mob-more-marquee-nine.jpg"


function GoldMiningExplorationMobile() {
  const sections = [
    {
      title: "Area Selection",
      items: [
        "Research and Data Collection: Utilize geological maps and historical data to find regions with potential gold deposits.",
        "Satellite Imagery and Remote Sensing: Use technology to spot promising features remotely."
      ]
    },
    {
      title: "Geological Mapping",
      items: [
        "Field Surveys: Conduct surveys to map geological features like rock formations and fault lines.",
        "Structural Analysis: Study geological structures to locate potential gold deposits."
      ]
    },
    {
      title: "Geochemical Analysis",
      items: [
        "Sample Collection: Collect soil, rock, and sediment samples systematically.",
        "Laboratory Testing: Analyze samples for trace elements indicating gold presence."
      ]
    },
    {
      title: "Geophysical Surveys",
      items: [
        "Magnetic Surveys: Detect magnetic variations in rocks.",
        "Seismic and Electromagnetic Surveys: Identify subsurface structures and ore bodies."
      ]
    },
    {
      title: "Drilling",
      items: [
        "Core and Reverse Circulation Drilling: Extract samples to assess mineral content.",
        "Assaying: Test samples for gold concentration."
      ]
    },
    {
      title: "Resource Estimation",
      items: [
        "Data Analysis: Estimate deposit size and grade using drilling data.",
        "3D Modelling: Visualize the deposit with 3D software."
      ]
    },
    {
      title: "Feasibility Studies",
      items: [
        "Economic Assessments: Analyze economic viability through PEA, PFS, and FS."
      ]
    },
    {
      title: "Permitting and Compliance",
      items: [
        "Environmental Impact Assessments: Mitigate environmental impacts.",
        "Regulatory Approvals: Secure necessary permits and conduct community consultations."
      ]
    },
    {
      title: "Development and Mining",
      items: [
        "Mine Planning and Construction: Plan and build infrastructure for mining operations.",
        "Production: Begin extraction and processing of gold."
      ]
    },
    {
      title: "Post-Exploration Monitoring",
      items: [
        "Environmental Monitoring and Site Rehabilitation: Monitor impacts and restore land post-mining."
      ]
    }
  ];

  return (
    <div className='mob-gme-container'>
      <div className='mob-gme-sub-container'>
        <div className='mob-gme-first-container'>
          <div className='mgfc-one'>01 TO 10 YEARS</div>
          <div className='mgfc-two'>GOLD MINE EXPLORATION</div>
        </div>
        <div className='mob-gme-second-container'>
          <div className='mgsc-one'></div>
          <div className='mgsc-two'>
            Exploring for gold mines is a challenging and complex process, demanding time, financial investment, and expertise across multiple fields like geography, geology, chemistry, and engineering. The chances of a discovery leading to a developed mine are very slim—less than 0.1% of prospected sites become productive, and only 10% of global gold deposits hold enough gold to justify further exploration.
            Once the local geology and a potentially viable deposit are understood, the gold ore body can be modeled, and its feasibility assessed.
          </div>
          <div className='mgsc-three'></div>
          <div className='mgsc-four'>
            <span className='mgscf-span'>PROSPECTING</span>
            <span className='mgscf-span'>VIABLITY</span>
            <span className='mgscf-span'>GEOLOGY</span>
          </div>
        </div>
      </div>

      <div className='mob-gme-sub-container-second'>
        <div className='mgfc-one'>0 TO 05 YEARS</div>
        <div className='mgscs-two'>
          GOLD MINE DEVELOPMENT
        </div>
        <div className='mgscs-three'>
          Building the Future: From Mine to Community
        </div>

        <div style={{ width: "100%" }}>
          <div className='marquee-containerr'>
            <div class="marqueeee">
              <div className='mq-sccc'>
                <><img src={Mimageone} alt='mo' className='mmc-img-new' /></>
                <><img src={Mimagetwo} alt='mo' className='mmc-img-new' /></>
                <><img src={Mimagethree} alt='mo' className='mmc-img-new' /></>
                <><img src={Mimagefour} alt='mo' className='mmc-img-new' /></>
                <><img src={Mimagefive} alt='mo' className='mmc-img-new' /></>
                <><img src={Mimagesix} alt='mo' className='mmc-img-new' /></>
                <><img src={Mimageseven} alt='mo' className='mmc-img-new' /></>
                <><img src={Mimageeight} alt='mo' className='mmc-img-new' /></>
                <><img src={Mimagenine} alt='mo' className='mmc-img-new' /></>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gold-exploration-container">
        {sections.map((section, index) => (
          <div key={index} className="section">
            <h3 className="section-title">{section.title}</h3>
            <ul className="section-list">
              {section.items.map((item, i) => (
                <li key={i} className="section-item">{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className='mob-gmo-container'>
        <div className='mgfc-one'>10 TO 35 YEARS</div>
        <div className='mob-mgc-text'>GOLD MINE OPERATIONS</div>
        <div className='mob-mgc-text-two'>The operational phase of a gold mine involves extracting and processing ore into doré, a gold alloy of 60-90% purity. Economic viability depends on factors like gold prices and production costs, with low-grade ores becoming profitable when prices rise. Mine plans are regularly updated based on market shifts, technical data, and technological advancements.
          <div>Increasingly, modern mines use electrification, digitalisation, and automation to enhance efficiency and sustainability, making the gold mining process smarter, cleaner, and more productive."</div>
          <div>This version is concise yet still conveys the key points.</div></div>
      </div>

      <div className='mob-gmo-card-container'>
        <div className='mob-gmo-first-card'>
          <div className='mob-card-top-one'></div>
          <div className='mob-card-bottom'>
            <div className='mob-cb-head'>Closure & Decommissioning</div>
            <div className='mob-cb-text'>Once a mine stops operating, either due to depleted ore or unprofitable deposits, the focus shifts to <span className='cb-span'>Decommissioning, Dismantling, and land rehabilitation.</span> Mine closure is complex, and companies must continue monitoring the site long after closure.</div>
          </div>
        </div>
        <div className='mob-gmo-first-card'>
          <div className='mob-card-top-two'></div>
          <div className='mob-card-bottom'>
            <div className='mob-cb-head'>Closure & Decommissioning</div>
            <div className='mob-cb-text'>Mining companies remain responsible for a site long after closure, usually for 5 to 10 years or more. During this period, the land is<span className='cb-span'> Rehabilitated, cleaned, and re-vegetated</span> to ensure long-term</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoldMiningExplorationMobile

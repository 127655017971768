import React from 'react'
import "./Pillars.css"

function Pillars() {
    return (
        <div className='pillar-primary-container'>
            <div className="pillers-head-contt">
                <p className="pillar-heading">PILLARS OF SUCCESS</p>
                <p className="pillar-sub-heading">Encompassing financial power, expertise, partnerships, and diverse industries.</p>
            </div>

            <div className="pillar-card-container">
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">GLOBAL PRESENCE</p>
                    <p>Through 7 strategic global partnerships, we foster collaboration and achieve excellence.</p>
                </div>
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">PROFESSIONAL WORKFORCE</p>
                    <p>Over 70 skilled professionals drive our continued success and innovation.</p>
                </div>
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">NET WORTH</p>
                    <p>Our $20M+ net worth reflects our financial strength and exemplary leadership.</p>
                </div>
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">INDUSTRY EXPERTISE</p>
                    <p>With 18+ years of experience, we deliver seasoned expertise to every venture pursue.</p>
                </div>
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">CLEAR VISION AND MISSION</p>
                    <p>Our leadership unites resources with a clear vision, setting milestones for success.</p>
                </div>
                <div className="pillar-card-div">
                    <p className="pillar-card-heading">ADAPTABILITY AND INNOVATION</p>
                    <p>Our agile team thrives on innovation, adapting swiftly to change and solving challenges.</p>
                </div>
            </div>

        </div>
    )
}

export default Pillars
